import { FaSteam, FaYoutube, FaInstagram, FaTwitch, FaTwitter } from "react-icons/fa";


export default function SocialIcons() {
    const data = [
        {
            "title": "Steam",
            "url": "https://steamcommunity.com/id/KamikazeEddi/",
            "external": true,
            "icon": <FaSteam/>
        },
        {
            "title": "Youtube",
            "url": "https://www.youtube.com/@KamikazeEddi",
            "external": true,
            "icon": <FaYoutube/>
        },
        {
            "title": "Instagram",
            "url": "https://instagram.com/kamikazeeddi",
            "external": true,
            "icon": <FaInstagram/>
        },
        {
            "title": "Twitch",
            "url": "https://www.twitch.tv/kamikazeeddi",
            "external": true,
            "icon": <FaTwitch/>
        },
        {
            "title": "Twitter",
            "url": "https://twitter.com/kamikazeeddi",
            "external": true,
            "icon": <FaTwitter/>
        }
    ]

    return (
        <>
            <section className="socialicons">
                <ul>
                    {data.map((item, index) => (
                        <li key={index}><a href={item.url} target="_blank">{item.icon}</a></li>
                    ))}
                </ul>
            </section>
        </>
    )
}