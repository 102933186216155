export default function System() {
    return (
        <>
            <section className="system">
                <h3>System</h3>
                <p>
                    MSI Optix MAG27QRFD-QD 2560x1440px<br/>
                    MSI MAG Tomahawk Max WiFi Intel Z790 So.1700 DDR5 ATX Retail<br />
                    Intel Core i9 14900K 24 3.20GHz <br/>
                    32GB G.Skill Trident Z5 RGB DDR5-7200 DIMM CL34 Dual Kit<br />
                    <br/>
                    MSI GeForce RTX 3060 Ti Gaming X 8GB<br/>
                    <br/>
                    Gehäuse SilverStone TJ07 (schwarz, ohne Fenster)<br/>
                    1500 Watt be quiet! Dark Power Pro 12 Modular 80+ Titanium<br/>
                    be quiet! Dark Rock Pro 5 Tower Kühler<br/>
                    <br/>
                    1TB Samsung 980 Pro M.2 PCIe<br/>
                    2TB Samsung 870 QVO 2.5″<br/>
                    <br/>
                    Tastatur Logitech G 815 Linear<br/>
                    Maus Logitech G PRO Wireless HERO 25K<br/>
                    Xbox Elite Wireless Controller Series 2<br/>
                    <br/>
                    (Stand 18.07.2024)
                </p>
            </section>
        </>
    )
}