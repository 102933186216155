import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import Intro from "./Intro";
import Topic from "./Topic";
import System from "./System";
import SocialIcons from "./SocialIcons";
import dataTopics from "../data/topics.json";

export default function App() {

  const TRACKING_ID = "G-CHWWK3FVTT";

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
    }
  }, [])

  return (
    <div className="app">
      <header className="app-header">
      </header>

      <div className="app-content">
        <Intro />
        {dataTopics.map((topic, index) => (
          <Topic key={index} data={topic} />
        ))}
        <System />
      </div>

      <footer className="app-footer">
        <SocialIcons />
      </footer>
    </div>
  );
}
