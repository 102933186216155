export default function Topic(props) {
    
    const data = props.data;
    
    return (
        <>
            <section className="topic">
                    <img src={data.img} alt={data.title}/>
                    <div>
                        <h3>{data.title}</h3>
                        <ul>
                            {data.videos.map((video, index) => (
                                <li key={index}><a href={video.url} rel="noreferrer" target="_blank">{video.title}</a></li>
                            ))}
                        </ul>
                    </div>
            </section>
        </>
    )
}